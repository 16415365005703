
// Must be sync with asysgo constant
export const ScopeTypes = Object.freeze({
  Global: 'GLOBAL',
	Country: 'COUNTRY',
	State: 'STATE',
	Region: 'REGION',
	Zone: 'ZONE',
	City: 'CITY',
	ShopType: 'SHOP_TYPE',
	BusinessType: 'BUSINESS_TYPE',
	Shop: 'SHOP',
});

/*
 * Workaround para cargar el titulo desde el /tt-score se envia un request como scope Global por default
 */
export const LabelScopeTypeGlobal = 'Global'

// Must be sync with asysgo constant
export const OriginTypes = Object.freeze({
  MSDimension: 'MS_DIMENSION',
  MSAttribute: 'MS_ATTRIBUTE',
  MSQuestion: 'MS_QUESTION',
	MSSatisfaction: 'MS_SATISFACTION',
	TSAttribute: 'TS_ATTRIBUTE',
	TSProblem: 'TS_PROBLEM',
  TsProblemCategory: 'TS_PROBLEM_CATEGORY',
	TSSatisfaction: 'TS_SATISFACTION',
  TsTopic: 'TS_TOPIC',
});

export const ScopeKeySep = '_';

export const classNamesModal = Object.freeze({
	header: "weglot-translate",
	body: "weglot-translate",
	footer: "weglot-translate",
});

export const classNamesInput = Object.freeze({
	input: "weglot-translate",
});

export const classNamesInputTextarea = Object.freeze({
	textarea: "weglot-translate",
});
