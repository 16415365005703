import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { Button, DatePicker, Input, Modal, Select, Spin, TreeSelect } from "antd";
import { GlobalContext } from "./contexts/GlobalContext.jsx";
import { defaultCatchValidation as dcvTT, login, createActionPlan, getCustomerTags } from "../services/task-tracking.js";
import { defaultCatchValidation as dcvAsysgo, getTTScopes, getTTScore } from "../services/asysgo.js";
import LoadSpinner from "./shared/LoadSpinner.jsx";
import ProgressBar from "./shared/ProgressBar.jsx";
import { AppRoutes, ResolvedAppRoutes } from "../utils/app-routes.js";
import { getSessionScopeVars, getPreselectedScope } from "../utils/fns.js";
import { classNamesInput, classNamesInputTextarea, classNamesModal, LabelScopeTypeGlobal, OriginTypes, ScopeTypes } from "../utils/constants.js";

let ac = new AbortController();

function parseURLData(userToken, customerCode, projectID, originType, searchParams) {
  const result = { fields: {}, isValid: false };
  if (!userToken) return result;
  result.fields.userToken = userToken;

  if (!customerCode) return result;
  result.fields.customerCode = customerCode;

  if (isNaN(projectID) || projectID <= 0) return result;
  result.fields.projectID = Number(projectID);

  if (!Object.values(OriginTypes).includes(originType)) return result;
  result.fields.originType = originType;

  const {
    dataSourceIdx,
    questionNumber,
    dimensionNumber,
    attributeNumber,
    topicTitle,
    problemCatTitle,
    countryCode,
    stateCode,
    regionCode,
    zoneCode,
    cityCode,
    shopID,
    shopTypeCode,
    businessTypeCode,
  } = Object.fromEntries([...searchParams]);

  switch (originType) {
    case OriginTypes.MSDimension: {
      if (isNaN(dimensionNumber) || dimensionNumber <= 0) return result;
      // originFields should have dimensionNumber (positive number)
      result.fields.originFields = {
        dimensionNumber: Number(dimensionNumber),
      };
      break;
    }
    case OriginTypes.MSAttribute: {
      if (isNaN(dimensionNumber) || dimensionNumber <= 0) return result;
      if (isNaN(attributeNumber) || attributeNumber <= 0) return result;
      // originFields should have dimensionNumber and attributeNumber (positive numbers)
      result.fields.originFields = {
        dimensionNumber: Number(dimensionNumber),
        attributeNumber: Number(attributeNumber),
      };
      break;
    }
    case OriginTypes.MSQuestion: {
      if (isNaN(dimensionNumber) || dimensionNumber <= 0) return result;
      if (isNaN(attributeNumber) || attributeNumber <= 0) return result;
      if (isNaN(questionNumber) || questionNumber <= 0) return result;
      // originFields should have dimensionNumber, attributeNumber, and questionNumber (positive numbers)
      result.fields.originFields = {
        dimensionNumber: Number(dimensionNumber),
        attributeNumber: Number(attributeNumber),
        questionNumber: Number(questionNumber),
      };
      break;
    }
    case OriginTypes.TSAttribute: {
      if (isNaN(dataSourceIdx)) return result;
      if (isNaN(questionNumber) || questionNumber <= 0) return result;
      // originFields should have dataSourceIdx (number) and questionNumber (positive number)
      result.fields.originFields = {
        dataSourceIdx: Number(dataSourceIdx),
        questionNumber: Number(questionNumber),
      };
      break;
    }
    case OriginTypes.TsTopic: {
      result.fields.originFields = {
        topicTitle: topicTitle
      }
      break;
    }
    case OriginTypes.TsProblemCategory: {
      result.fields.originFields = {
        problemCatTitle,
      }
      break;
    }
    case OriginTypes.MSSatisfaction:
    case OriginTypes.TSProblem:
    case OriginTypes.TSSatisfaction:
    default: {
      // originFields is not required
      break;
    }
  }

  result.isValid = true;

  result.preselectedScope = getPreselectedScope(countryCode, stateCode, regionCode, zoneCode, cityCode, shopID, shopTypeCode, businessTypeCode);

  return result;
}

const ExecRefreshScoreModes = {
  NoRefresh: 0,
  ScoreOnly: 1,
  ScoreAndTitleAndRelatedTags: 2,
}
export default function CreateActionPlanScreen() {
  const navigate = useNavigate();
  const { userToken, customerCode, projectID, originType } = useParams();

  const [searchParams] = useSearchParams();

  const {
    session,
    setSession,
    updateSession,
    cleanSession,
    getActionPlanPriorityData,
    getActionPlanStatusData,
  } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [execRefreshScore, setExecRefreshScore] = useState(ExecRefreshScoreModes.NoRefresh);

  const [scopes, setScopes] = useState([/* { value: <string>, label: <string>, scopeKeyTreeData: <AntdTreeData>} */]);
  const showSelectScope = useMemo(() => Array.isArray(scopes) && scopes.length > 0, [scopes]);

  const scopeTypeOptions = useMemo(() => (
    scopes.map(({ value, label }) => ({ value, label }))
  ), [scopes]);

  const [selectedScopeType, setSelectedScopeType] = useState(ScopeTypes.Global);
  const [selectedScopeKey, setSelectedScopeKey] = useState();
  const [apScopeFields, setApScopeFields] = useState({ scopeTypeLabel: LabelScopeTypeGlobal });

  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [showTagFeature, setShowTagFeature] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);

  // START Modal: Create action-plan
  const [isVisibleApModal, setIsVisibleApModal] = useState(false);

  const [apProjectID, setApProjectID] = useState();
  const [apCustomerCode, setApCustomerCode] = useState();
  const [apOriginType, setApOriginType] = useState();
  const [apOriginFields, setApOriginFields] = useState();

  const [apTitle, setApTitle] = useState("");
  const [apInitialScore, setApInitialScore] = useState();
  
  const [apDescription, setApDescription] = useState("");
  const [apDescriptionRequired, setApDescriptionRequired] = useState(false);
  const [apStartDate, setApStartDate] = useState(dayjs());
  const [apDeadlineDate, setApDeadlineDate] = useState(dayjs());
  const [apPriority, setApPriority] = useState("0");
  const [apStatus, setApStatus] = useState("false");
  const [apTags, setApTags] = useState([]);
  const [apReason, setApReason] = useState("");
  const [apResource, setApResource] = useState("");

  const [creatingActionPlan, setCreatingActionPlan] = useState(false);
  const handleApSave = () => {
    if (creatingActionPlan) {return;}

    // Validations
    let isValid = true;
    const patchedDescription = String(apDescription).trim();
    if (!patchedDescription) {
      isValid = false;
      setApDescriptionRequired(true);
    }

    const patchedStartDate = apStartDate.format("YYYY/MM/DD");
    if (!patchedStartDate) {
      isValid = false;
    }

    const patchedDeadlineDate = apDeadlineDate.format("YYYY/MM/DD");
    if (!patchedDeadlineDate) {
      isValid = false;
    }

    if(!isValid) {return;}
    
    setCreatingActionPlan(true);
    createActionPlan(
      session.accessToken,
      apCustomerCode,
      apProjectID,
      selectedScopeType,
      apScopeFields,
      apOriginType,
      apOriginFields,
      apInitialScore,
      apTitle,
      patchedDescription,
      patchedStartDate,
      patchedDeadlineDate,
      apPriority,
      apStatus,
      apReason,
      apResource,
      apTags,
    )
    .then(json => {
      updateSession(getSessionScopeVars(json.scopeMetadata));
      navigate(ResolvedAppRoutes.ActionPlan({ actionPlanID: json.actionPlanID }));
    })
    .catch(json => {
      dcvTT(json, navigate);
      if (!isNaN(json.errorCode) && json.message) {
        switch (json.errorCode) {
          case 3: { // Invalid action plan scope
            setErrorMsg(json.message);
          break;
          }
          default: {}
        }
      }
    })
    .finally(() => {
      setCreatingActionPlan(false);
      setIsVisibleApModal(false);
    });
  };
  const handleApCancel = () => {
    if (creatingActionPlan) {return;}
    setIsVisibleApModal(false);
  };
  // END Modal: Create action-plan



  const [refreshingScore, setRefreshingScore] = useState(false);
  const refreshScore = useCallback((scopeType, scopeFields, refreshTitleAndRelatedTags = false) => {
    if (!showSelectScope) { return; }

    setRefreshingScore(true);

    ac.abort();
    ac = new AbortController();

    getTTScore(
      ac,
      session.accessToken,
      apCustomerCode,
      apProjectID,
      scopeType,
      scopeFields,
      apOriginType,
      apOriginFields,
      refreshTitleAndRelatedTags,
      refreshTitleAndRelatedTags,
      false // does not get the latest wave score. It gets the score associated with the action plan wave
    ).then(data => {
      if (refreshTitleAndRelatedTags) {
        setApTitle(data.title);
        if (Array.isArray(data.relatedTags)) {
          setApTags(data.relatedTags);
        }
      }
      setApInitialScore(data.score);
    })
    .catch(json => {
      dcvAsysgo(json, navigate);
    })
    .finally(() => {
      setRefreshingScore(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelectScope, session, apCustomerCode, apProjectID, apOriginType, apOriginFields]);

  const { showSelectScopeType, scopeKeyOptions } = useMemo(() => {
    const options = scopes.find(({value}) => value === selectedScopeType)?.scopeKeyTreeData;
    const show = Array.isArray(options) && options.length > 0;

    if (!show) {
      setExecRefreshScore((prev) => Math.max(prev, ExecRefreshScoreModes.ScoreOnly));
    }

    return ({
      showSelectScopeType: show,
      scopeKeyOptions: options,
    });
  }, [selectedScopeType, scopes]);

  const showContinueButton = !showSelectScopeType || selectedScopeKey;

  useEffect(() => {
    const { fields, isValid, preselectedScope } = parseURLData(userToken, customerCode, projectID, originType, searchParams);

    if (!isValid) {
      navigate(AppRoutes.Forbidden);
      return;
    }

    cleanSession();
    login(fields.userToken, fields.customerCode)
    .then(json => {
      // Set user data in context
      setSession({
        accessToken: json.accessToken, // ttgo auth token
        userToken: fields.userToken, // asysgo auth token
        userName: json.user.name,
        userEmail: json.user.email,
        companyName: json.customer.companyName,
        customerName: json.customer.name,
        customerLogo: json.customer.logo,
        qsScopeType: '',
        qsScopeKeys: '',
        qsArray: null,
        more: json.more,
      });
      
      setIsLoadingTags(true);
      getCustomerTags(json.accessToken)
        .then(({ tags }) => {
          if (Array.isArray(tags) && tags.length > 0) {
            setShowTagFeature(true);

            setTagOptions(tags.map(({ id, title }) => ({
              value: id,
              label: title,
            })));
          }
        })
        .catch(json => {
          dcvTT(json, navigate);
        })
        .finally(() => {
          setIsLoadingTags(false);
        });

      getTTScopes(
        json.accessToken,
        fields.customerCode,
        fields.projectID,
      )
        .then((scopesData) => {
          setApProjectID(fields.projectID);
          setApCustomerCode(fields.customerCode);
          setApOriginType(fields.originType);
          setApOriginFields(fields.originFields);

          setScopes(scopesData.scopeTypes);

          // Pre-select scope type value and derivated fields
          if (preselectedScope.type && Array.isArray(scopesData.scopeTypes)) {
            const scopeTypeData = scopesData.scopeTypes.find(({ value }) => (value === preselectedScope.type));
            if (scopeTypeData) {
              setSelectedScopeType(preselectedScope.type);
              let derivatedFields = { scopeTypeLabel: scopeTypeData.label };
              // Pre-select scope key value and derivated fields
              if (preselectedScope.key && Array.isArray(scopeTypeData.scopeKeyTreeData)) {
                function findScopeKeyTreeData(treeData) {
                  if (Array.isArray(treeData)) {
                    for (const element of treeData) {
                      const { value, children } = element;
      
                      if (preselectedScope.keyHasShop) {
                        // Value found
                        if (value.endsWith(preselectedScope.key)) {
                          return element;
                        }

                        // Search each leaf to get the full value
                        const result = findScopeKeyTreeData(children);
                        if (result) { return result; }
                      } else {
                        // Value found
                        if (value === preselectedScope.key) {
                          return element;
                        }
                        // Keep searching under the parent branch to get the value
                        if (preselectedScope.key.includes(value)) {
                          const result = findScopeKeyTreeData(children);
                          if (result) { return result; }
                        }
                      }
                    }
                  }
                }

                const scopeKeyData = findScopeKeyTreeData(scopeTypeData.scopeKeyTreeData);
                if (scopeKeyData) {
                  setSelectedScopeKey(scopeKeyData.value);
                  derivatedFields = {
                    ...derivatedFields,
                    scopeKeyLabel: scopeKeyData.title,
                    ...scopeKeyData.metadata,
                  };
                }
              }
              setApScopeFields(derivatedFields);
            }
          }
          setExecRefreshScore(ExecRefreshScoreModes.ScoreAndTitleAndRelatedTags);
        })
        .catch(json => {
          dcvAsysgo(json, navigate);
        })
        .finally(() => {
          setIsLoading(false);
        });

    })
    .catch(json => {
      dcvTT(json, navigate);
      setIsLoading(false);
    });
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (execRefreshScore > ExecRefreshScoreModes.NoRefresh) {
      const refreshTitleAndRelatedTags = execRefreshScore === ExecRefreshScoreModes.ScoreAndTitleAndRelatedTags;
      setExecRefreshScore(ExecRefreshScoreModes.NoRefresh);
      refreshScore(selectedScopeType, apScopeFields, refreshTitleAndRelatedTags);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execRefreshScore]);

  return (
    <>
      <LoadSpinner isLoading={isLoading} errorMsg={errorMsg} />
      {showSelectScope && (
        <>
          <label className="label-title">Nuevo plan de acción:</label>
          <label className="label-data">{apTitle}</label>
          <div className="field my-2">
            <div className="control">
              <label className="label-title">Seleccione el alcance del plan:</label><br/>
              <div
              >
                <Select
                  style={{ width: '100%', maxWidth: 600 }}
                  size="large"
                  value={selectedScopeType}
                  onChange={(value, option) => {
                    setSelectedScopeType(value);
                    setSelectedScopeKey();
                    setApScopeFields({ scopeTypeLabel: option.label });
                  }}
                  options={scopeTypeOptions}
                />
              </div>
            </div>
          </div>
          {showSelectScopeType && (
            <TreeSelect
              key={selectedScopeType}
              style={{ width: '100%', maxWidth: 600 }}
              className="my-2"
              size="large"
              placeholder="Seleccione una opción"
              value={selectedScopeKey}
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              treeLine
              showSearch
              treeData={scopeKeyOptions}
              treeDefaultExpandAll
              onSelect={(value, node) => {
                setSelectedScopeKey(value);
                refreshScore(selectedScopeType, node.metadata);
                setApScopeFields((prev) => ({
                  ...prev,
                  scopeKeyLabel: node.title,
                  ...node.metadata,
                }));
              }}
              filterTreeNode={(search, item) => (
                item.isLeaf && item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
              )}
            />
          )}

          {showContinueButton && (
            <>
              <Spin size="small" spinning={refreshingScore}>
              <div className="field my-2">
                <div className="control">
                  <div className="is-flex">
                    <label className="label-title">{`Desempeño inicial: `}</label>
                    <ProgressBar percent={apInitialScore} indicator="attribute" />
                  </div>
                </div>
              </div>
              </Spin>
              <Button loading={refreshingScore} onClick={() => { setIsVisibleApModal(true); }}>
                <span className="weglot-translate">Continuar</span>
              </Button>
            </>
          )}
        </>
      )}
      <div className="weglot-translate">
        <Modal
          title="Crear plan de acción"
          classNames={classNamesModal}
          open={isVisibleApModal}
          closable={false}
          okText="Crear Plan"
          cancelText="Volver"
          onOk={handleApSave}
          onCancel={handleApCancel}
        >
          <Spin tip="Creando plan" size="large" spinning={creatingActionPlan}>
            <div className="field">
              <div className="control">
                <label>Descripción breve:</label><br/>
                <Input
                  size="large"
                  classNames={classNamesInput}
                  maxLength="100"
                  placeholder="Ingrese descripción breve.."
                  value={apDescription}
                  onChange={event => {
                    setApDescription(event.target.value);
                    if (event.target.value) {
                      setApDescriptionRequired(false);
                    } else {
                      setApDescriptionRequired(true);
                    }
                  }}
                  />
                  {apDescriptionRequired && (
                    <label className="has-text-danger">
                      * Requerido
                    </label>
                  )}
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>Fecha de inicio y final:</label><br/>
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  size="large"
                  format="DD/MM/YYYY"
                  value={[apStartDate, apDeadlineDate]}
                  onChange={arrayData => {
                    if (Array.isArray(arrayData) && arrayData.length === 2) {
                      setApStartDate(arrayData[0]);
                      setApDeadlineDate(arrayData[1]);
                    } else {
                      setApStartDate(dayjs());
                      setApDeadlineDate(dayjs());
                    }
                  }}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>Prioridad:</label><br/>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    size="large"
                    value={apPriority}
                    onChange={value => setApPriority(value)}
                    options={[
                      { value: "0", label: getActionPlanPriorityData(0).text },
                      { value: "1", label: getActionPlanPriorityData(1).text },
                      { value: "2", label: getActionPlanPriorityData(2).text },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>Estado:</label><br/>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    size="large"
                    value={apStatus}
                    onChange={value => setApStatus(value)}
                    options={[
                      { value: "false", label: getActionPlanStatusData(false).text },
                      { value: "true", label: getActionPlanStatusData(true).text },
                    ]}
                  />
                </div>
              </div>
            </div>
            {showTagFeature && (
              <Spin size="small" spinning={isLoadingTags}>
                <div className="field weglot-translate">
                  <div className="control">
                    <label>Tags:</label><br/>
                    <div>
                      <Select
                        style={{ width: '100%' }}
                        size="large"
                        mode="multiple"
                        placeholder="(opcional) Tags relacionados al plan ..."
                        value={apTags}
                        onChange={value => setApTags(value)}
                        options={tagOptions}
                        notFoundContent="No hay tags disponibles"
                      />
                    </div>
                  </div>
                </div>
              </Spin>
            )}
            <div className="field">
              <div className="control">
                <label>Causa Raíz:</label><br/>
                <Input.TextArea
                  classNames={classNamesInputTextarea}
                  style={{ height: 110, resize: 'none' }}
                  size="large"
                  placeholder="(opcional) Descripción de la causa ..."
                  value={apReason}
                  onChange={event => {
                    setApReason(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>Recurso:</label><br/>
                <Input.TextArea
                  classNames={classNamesInputTextarea}
                  style={{ height: 110, resize: 'none' }}
                  size="large"
                  placeholder="(opcional) Descripción del recurso ..."
                  value={apResource}
                  onChange={event => {
                    setApResource(event.target.value);
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </>
  );
};
